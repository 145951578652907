<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="field">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-body">
                <div class="field has-addons">
                  <div class="control has-icons-left has-icons-right">
                    <input class="input"
                      v-model="filter.query"
                      type="text"
                      placeholder="Start typing to filter"
                      @input="search">
                    <span class="icon is-left">
                      <i class="mdi mdi-magnify mdi-18px" />
                    </span>
                  </div>
                  <div class="control">
                    <a class="button is-primary is-outlined tooltip"
                      data-tooltip="Reset search"
                      @click="reset()">
                      <span class="icon">
                        <i class="mdi mdi-close mdi-24px" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="filter.pageIndex"
      :page-size="filter.pageSize"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      :sort-column="filter.sortColumn"
      :sort-order="filter.sortOrder"
      @pageChanged="onPageChange"
      @sort="sort">
      <tr v-for="(item, index) in availableItems"
        :key="index"
        @dblclick="addNewItem(item, index)"
        :class="{ 'is-selected' : selectedRow === index }"
        @click="highlightSelected(index, $event)">
        <td>{{ item.itemNo }}</td>
        <td>{{ item.description }}</td>
        <td class="has-vertical-middle has-text-centered is-content-width">
          <a class="button is-primary is-small is-inverted"
            @click="addNewItem(item, index)">
            <span class="icon is-medium">
              <i class="mdi mdi-plus mdi-24px" />
            </span>
          </a>
        </td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
    </bulma-table>
  </div>
</template>

<script>
import QuoteService from '../QuoteService'
import { AvailableOpgColumns } from '../columns'
import { ItemCategoryTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import _debounce from 'lodash.debounce'
// import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import Guid from '@/components/Guid'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import { QuoteItemModel } from '@/classes/viewmodels'

export default {
  name: 'AvailableOpgs',
  components: {
    BulmaTable
  },
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: {
      type: Array,
      default: function() {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    vehiclePaintGroup: {
      type: String,
      default: ''
    },
    paintGroupRate: {
      type: Number,
      default: 0
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 8
    }
  },
  data() {
    return {
      innerValue: null,
      selectedRow: null,
      isTableLoading: false,
      totalRows: 0,
      filter: {
        countryId: Guid.empty(),
        query: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      availableItems: null,
      selectedItems: []
    }
  },
  computed: {
    columns() {
      return AvailableOpgColumns
    },
    newItems() {
      return this.value.filter(i => i.isNew && !i.deleted)
    }
  },
  created() {
    // this.innerValue = this.value
    this.getAvailableOpgs()
  },
  mounted() {},
  methods: {
    async getAvailableOpgs() {
      this.isTableLoading = true
      this.availableItems = await QuoteService.getAvailableOpgItems(this.filter)
      this.totalRows = this.availableItems.length > 0 ? this.availableItems[0].totalRows : 0
      this.isTableLoading = false
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getAvailableOpgs()
    },
    sort(name, order) {},
    reset() {
      this.selectedItems = []
      this.filter.query = ''
      this.filter.pageIndex = 1
      this.getAvailableOpgs()
    },
    search: _debounce(function() {
      this.filter.pageIndex = 1
      this.getAvailableOpgs()
    }, 500),
    cancel(value) {
      this.selectedItems = value
    },
    addNewItem(item, index) {
      const existingItem = this.value.find(i => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && !i.deleteOtherItem)
      if (!existingItem) {
        let newItem = new QuoteItemModel(this.quoteId, item.itemNo, item.description, ItemCategoryTypes.OPG)
        newItem.lineNumber = this.nextLineNumber
        newItem.hourValue = 0
        newItem.rate = this.paintGroupRate
        newItem.dollarValue = 0
        newItem.opgCode = this.vehiclePaintGroup
        newItem.sortNo = this.value.length ? Math.max(...this.value.map(i => i.sortNo)) + 1 : 1
        this.$emit('item-added', newItem)
      } else {
        this.$notification.openNotificationWithType('warning', 'Sublet', 'Item is already in selected list. Item not added.')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
