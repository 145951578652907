<template>
  <div>
    <div class="columns is-gapless">
      <div class="column">
        <div class="field">
          <div class="field is-horizontal">
            <div class="field-body">
              <div class="field-body">
                <div class="field has-addons">
                  <div class="control has-icons-left has-icons-right">
                    <input class="input"
                      v-model="filter.query"
                      type="text"
                      placeholder="Start typing to filter"
                      @input="search">
                    <span class="icon is-left">
                      <i class="mdi mdi-magnify mdi-18px" />
                    </span>
                  </div>
                  <div class="control">
                    <a class="button is-primary is-outlined tooltip"
                      data-tooltip="Reset search"
                      @click="reset()">
                      <span class="icon">
                        <i class="mdi mdi-close mdi-24px" />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
      :columns="columns"
      :page-index="filter.pageIndex"
      :page-size="filter.pageSize"
      :total-rows="totalRows"
      :is-loading="isTableLoading"
      :sort-column="filter.sortColumn"
      :sort-order="filter.sortOrder"
      @pageChanged="onPageChange"
      @sort="sort">
      <tr v-for="(item, index) in availableItems"
        :key="index"
        :class="{ 'is-selected' : selectedRow === index }"
        @click="highlightSelected(index, $event)">
        <td>{{ item.itemNo }}</td>
        <td @dblclick="addNewItems(item, index)">{{ item.description }}</td>
        <!-- <td class="has-text-right"
          @dblclick="addSingleNewItem(item, itemCategoryTypes.FIBER, true)">{{ getDisplayValue(itemCategoryTypes.FIBER, item.fgHour) | formatNumber($userInfo.locale) }}</td>
        <td class="has-text-right"
          @dblclick="addSingleNewItem(item, itemCategoryTypes.CRUSH, true)">{{ getDisplayValue(itemCategoryTypes.CRUSH, item.crushHour) | formatNumber($userInfo.locale) }}</td>
        <td class="has-text-right"
          @dblclick="addSingleNewItem(item, itemCategoryTypes.CD, true)">{{ getDisplayValue(itemCategoryTypes.CD, item.cdHour) | formatNumber($userInfo.locale) }}</td> -->
        <td class="has-text-right"
          @dblclick="addSingleNewItem(item, itemCategoryTypes.MECH, true)">{{ getDisplayValue(itemCategoryTypes.MECH, item.mechHour) | formatNumber($userInfo.locale) }}</td>
        <td class="has-vertical-middle has-text-centered is-content-width">
          <a class="button is-primary is-small is-inverted"
            @click="addNewItems(item, index)">
            <span class="icon is-medium">
              <i class="mdi mdi-plus mdi-24px" />
            </span>
          </a>
        </td>
      </tr>
      <template slot="empty">
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <span icon="icon is-large">
              <i class="mdi mdi-48px mdi-emoticon-sad" />
            </span>
            <p>Nothing</p>
          </div>
        </section>
      </template>
    </bulma-table>
  </div>
</template>

<script>
import QuoteService from '../QuoteService'
import { AvailableOtherLabourColumns } from '../columns'
import { ItemCategoryTypes, QuotingMethodTypes } from '@/enums'
import BulmaTable from '@/components/BulmaTable'
import _debounce from 'lodash.debounce'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import Guid from '@/components/Guid'
import { NumberFiltersMixin } from '@/components/mixins/filters'
import _isEmpty from 'lodash/isEmpty'
import { QuoteItemModel } from '@/classes/viewmodels'

export default {
  name: 'AvailableOtherLabours',
  components: {
    BulmaTable
  },
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    value: {
      type: Array,
      default: function () {
        return []
      }
    },
    quoteId: {
      type: String,
      default: ''
    },
    nextLineNumber: {
      type: Number,
      default: 0
    },
    pageSize: {
      type: Number,
      default: 8
    },
    quotingMethod: {
      type: String,
      default: ''
    },
    fgRate: {
      type: Number,
      default: 0
    },
    crushRate: {
      type: Number,
      default: 0
    },
    cdRate: {
      type: Number,
      default: 0
    },
    mechRate: {
      type: Number,
      default: 0
    },
    shopRate: {
      type: Number,
      default: 0
    },
    isNtar: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: null,
      selectedRow: null,
      isTableLoading: false,
      totalRows: 0,
      filter: {
        companyId: Guid.empty(),
        query: '',
        sortColumn: '',
        sortOrder: '',
        pageIndex: 1,
        pageSize: this.pageSize
      },
      availableItems: null,
      selectedItems: [],
      addedItems: []
    }
  },
  computed: {
    columns() {
      return AvailableOtherLabourColumns
    },
    newItems() {
      return this.value.filter((i) => i.isNew && !i.deleted)
    },
    itemCategoryTypes() {
      return ItemCategoryTypes
    },
    maxAddedItemsLineNumber() {
      return Math.max(...this.addedItems.map((a) => a.lineNumber), 0)
    }
  },
  created() {
    // this.innerValue = this.value
    this.getAvailableOtherLabours()
  },
  mounted() {},
  methods: {
    async getAvailableOtherLabours() {
      this.isTableLoading = true
      this.availableItems = await QuoteService.getAvailableOtherLabourItems(this.filter)
      this.totalRows = this.availableItems.length > 0 ? this.availableItems[0].totalRows : 0
      this.isTableLoading = false
    },
    highlightSelected(index, event) {
      this.selectedRow = index
    },
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.getAvailableOtherLabours()
    },
    sort(name, order) {},
    reset() {
      this.selectedItems = []
      this.filter.query = ''
      this.filter.pageIndex = 1
      this.getAvailableOtherLabours()
    },
    search: _debounce(function () {
      this.filter.pageIndex = 1
      this.getAvailableOtherLabours()
    }, 500),
    cancel(value) {
      this.selectedItems = value
    },
    findItem(item, type) {
      const existingItem = this.value.find(
        (i) => i.itemDesc === item.description && i.itemNo === item.itemNo && !i.deleted && i.itemType === type && !i.deleteOtherItem
      )
      return existingItem
    },
    generateItem(itemNo, description, hourValue, rate, type) {
      let newItem = new QuoteItemModel(this.quoteId, itemNo, description, type)
      newItem.lineNumber = Math.max(this.maxAddedItemsLineNumber + 1, this.nextLineNumber)
      newItem.hourValue = this.quotingMethod === QuotingMethodTypes.Hour ? hourValue : roundAwayFromZero(hourValue * rate)
      newItem.rate = rate
      newItem.dollarValue = roundAwayFromZero(hourValue * rate)
      newItem.opgCode = this.vehiclePaintGroup
      newItem.sortNo = this.value.length ? Math.max(...this.value.map((i) => i.sortNo)) + 1 : 1
      return newItem
    },
    addSingleNewItem(item, type, emit = false) {
      let hour = 0
      let rate = 0
      switch (type) {
        case ItemCategoryTypes.FIBER:
          hour = item.fgHour
          rate = this.isNtar ? this.shopRate : this.fgRate
          break
        case ItemCategoryTypes.CRUSH:
          hour = item.crushHour
          rate = this.isNtar ? this.shopRate : this.crushRate
          break
        case ItemCategoryTypes.CD:
          hour = item.cdHour
          rate = this.isNtar ? this.shopRate : this.cdRate
          break
        case ItemCategoryTypes.MECH:
          hour = item.mechHour
          rate = this.isNtar ? this.shopRate : this.mechRate
          break
      }
      if (!this.findItem(item, type)) {
        const newItem = this.generateItem(item.itemNo, item.description, hour, rate, type)
        this.addedItems.splice(this.addedItems.length, 1, newItem)
        if (emit) {
          // this.innerValue.splice(this.innerValue.length, 1, newItem)
          this.$emit('item-added', this.addedItems)
          this.addedItems = []
        }
      } else {
        this.$notification.openNotificationWithType('warning', 'Other Labour', `${type} item is already in selected list. Item not added.`)
      }
    },
    addNewItems(item, index) {
      this.addSingleNewItem(item, ItemCategoryTypes.MECH)

      if (!_isEmpty(this.addedItems)) {
        // const vm = this
        // this.addedItems.forEach(function(newItem) {
        //   vm.innerValue.splice(vm.innerValue.length, 1, newItem)
        // })
        this.$emit('item-added', this.addedItems)
        this.addedItems = []
      }
    },
    getDisplayValue(type, value) {
      let rate = 0
      switch (type) {
        case ItemCategoryTypes.FIBER:
          rate = this.isNtar ? this.shopRate : this.fgRate
          break
        case ItemCategoryTypes.CRUSH:
          rate = this.isNtar ? this.shopRate : this.crushRate
          break
        case ItemCategoryTypes.CD:
          rate = this.isNtar ? this.shopRate : this.cdRate
          break
        case ItemCategoryTypes.MECH:
          rate = this.isNtar ? this.shopRate : this.mechRate
          break
      }
      return this.quotingMethod === QuotingMethodTypes.Dollar ? roundAwayFromZero(rate * value) : value
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
